import React from 'react';

import './header.scss';

import UnlockModal from '../unlock/unlockModal.jsx';
import {
  ERROR,
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  CONFIGURE_RETURNED,
} from '../../constants/constants';
import { injected } from '../../stores/connectors';

import Store from '../../stores/store';
const { emitter, store } = Store;

class Wallet extends React.Component {
  constructor(props) {
    super(props);
    const account = store.getStore('account');
    const themeType = store.getStore('themeType');
    const activeClass = store.getStore('activeClass');
    this.headerRef = React.createRef();
    this.state = {
      activeClass,
      loading: false,
      account: account,
      assets: store.getStore('assets'),
      modalOpen: false,
      themeType: themeType,
      isSticky: false,
    };
  }

  componentWillMount() {
    emitter.on(ERROR, this.errorReturned);
    emitter.on(CONNECTION_CONNECTED, this.connectionConnected);
    emitter.on(CONNECTION_DISCONNECTED, this.connectionDisconnected);
    emitter.on(CONFIGURE_RETURNED, this.configureReturned);
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        injected
          .activate()
          .then((a) => {
            store.setStore({
              account: { address: a.account },
              web3context: { library: { provider: a.provider } },
            });
            emitter.emit(CONNECTION_CONNECTED);
            // console.log(a);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
      }
    });
  }

  componentWillUnmount() {
    const cachedRef = this.headerRef.current,
      observer = new IntersectionObserver(
        ([e]) => this.setState({ isSticky: e.intersectionRatio < 1 }),
        { threshold: [1] }
      );

    emitter.removeListener(ERROR, this.errorReturned);
    emitter.removeListener(CONNECTION_CONNECTED, this.connectionConnected);
    emitter.removeListener(
      CONNECTION_DISCONNECTED,
      this.connectionDisconnected
    );
    emitter.removeListener(CONFIGURE_RETURNED, this.configureReturned);

    observer.unobserve(cachedRef);
  }

  connectionConnected = () => {
    this.setState({ account: store.getStore('account') });
  };

  connectionDisconnected = () => {
    this.setState({ account: store.getStore('account'), loading: false });
  };


  configureReturned = () => {
    //.this.props.history.push('/')
  };


  errorReturned = (error) => {
    //TODO: handle errors
  };

  componentDidUpdate = () => {
    // console.log(this.state.isSticky);
  };

  renderModal = () => {
    return (
      <UnlockModal
        closeModal={this.closeModal}
        modalOpen={this.state.modalOpen}
      />
    );
  };

  unlockClicked = () => {
    this.setState({ modalOpen: true, loading: true });
  };

  closeModal = () => {
    this.setState({ modalOpen: false, loading: false });
  };

  render() {
    const { account, modalOpen } = this.state;
    var address = null;
    if (account.address) {
      address =
        account.address.substring(0, 6) +
        '...' +
        account.address.substring(
          account.address.length - 4,
          account.address.length
        );
    }
    return (
      <>
        {address && (
          <button
            className='btn navigator-unlock-btn'
            onClick={this.unlockClicked}
          >
            <span className='mr-2'>
              <b>Wallet</b>
            </span>
            <span>{address}</span>
          </button>
        )}
        {!address && (
          <button
            className='btn navigator-unlock-btn'
            onClick={this.unlockClicked}
          >
            <p className='mb-0'>CONNECT WALLET</p>
          </button>
        )}

        {modalOpen && this.renderModal()}
      </>
    );
  }
}

export default Wallet;
