import React, { useEffect, useState, useRef, useCallback } from 'react';
import moment from 'moment';

import Store from '../../stores/store';
const { store } = Store;

// export default function CountDown(props) {
//   const [timeForReduction, setTimeForReduction] = useState('');

//   useEffect(() => {
//     let interval = setInterval(() => {
//       const startBeastReduction = moment.unix(
//         store.store.startBeastReductionTimestamp
//       );
//       const diffDays = moment().diff(startBeastReduction, 'days');
//       const nextReduction = startBeastReduction.add(diffDays + 1, 'days');
//       let diff = moment.utc(nextReduction.diff(moment())).format('HH:mm:ss');
//       // setTimeForReduction(props?.pool.disableStake ? 0 : `${diff}`);
//       setTimeForReduction(`${diff}`);
//     }, 1000);
//     return () => {
//       clearInterval(interval);
//     };
//   }, [props.id]);

//   return <span>{timeForReduction}</span>;
// }

// const calculateDuration = (eventTime) =>
//   moment.duration(
//     Math.max(eventTime - Math.floor(Date.now() / 1000), 0),
//     'seconds'
//   );

function CountDown({ setIsCountDownEnd, date }) {
  const calculateTimeLeft = () => {
    const difference = +new Date(date) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  useEffect(() => {
    if (!timerComponents.length) {
      setIsCountDownEnd(true);
    }
  }, [timeLeft]);

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{' '}
      </span>
    );
  });
  return (
    <div>
      {timerComponents.length ? (
        <small>{timerComponents}</small>
      ) : (
        <span>MINT YOUR YFU GIRL</span>
      )}
    </div>
  );
}

export default CountDown;
