import React from 'react';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import { IoLogoDiscord, IoLogoMedium, IoLogoTwitter, IoLogoYoutube, IoLogoInstagram, IoLogoTiktok } from 'react-icons/io5';

import HeroSection from './Sections/HeroSection';
import BiosTeamCard from '../biosTeam/Card/Index';
import WelcomeSection from './Sections/welcomeSection';
import ChartSection from './Sections/chartSection/Index';
import RoadMapSection from './Sections/roadMapSection/Index';
import Faq from './Sections/faq/Index';
import TeamMembers from './Sections/teamMembers/Index';
import YfuTokenSection from './Sections/YfuTokenSection/Index';
import WhiteListSection from './Sections/whiteListSection/Index';

import './home.scss';

const Home = ({ account }) => {
  return (
    <div className='home-wrapper'>
      {/* <div className='text-center home-first-section-bg home-first-section'>
        <div className='py-0 pt-5 p-sm-5'>
          <div className='py-0 pt-5 p-sm-5'>
            <img
              alt=''
              src={require('../../assets/yfu/imf-logo.png')}
              style={{ maxWidth: '45%' }}
            />
            <img
              alt=''
              src={require('../../assets/yfu/anime-limited.png')}
              style={{ maxWidth: '45%' }}
            />
            <h4>Featuring Youko Horiuchi, instagram: @youkonejo</h4>
          </div>
        </div>
      </div> */}

      <div className='home-socials'>
        <a
          href='https://discord.com/invite/RGt5Ukw'
          target='_blank'
          rel='noopener noreferrer'
        >
          <IoLogoDiscord />
        </a>
        <a
          href='https://animelimited.medium.com/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img src={require('../../assets/yfu/m-logo.png')} alt='medium logo' />
        </a>
        <a
          href='https://twitter.com/AnimeLtd'
          target='_blank'
          rel='noopener noreferrer'
        >
          <IoLogoTwitter />
        </a>
        <a
          href='https://www.youtube.com/channel/UCc-ETacfyUryGaXqeks_oSQ/featured'
          target='_blank'
          rel='noopener noreferrer'
        >
          <IoLogoYoutube />
        </a>
        <a
          href='https://www.instagram.com/animelimited.yfu'
          target='_blank'
          rel='noopener noreferrer'
        >
          <IoLogoInstagram />
        </a>
        <a
          href='https://www.tiktok.com/@animelimited'
          target='_blank'
          rel='noopener noreferrer'
        >
          <IoLogoTiktok />
        </a>
      </div>

      <HeroSection />
      <WelcomeSection />
      <div className='divided-1'></div>
      <ChartSection />

      <div className='divided-2'>
        <p>
          Anime Limited Discord already has over 500 members! Join us to get the
          news as soon as possible and follow our latest announcements.
        </p>

        <a
          href='https://discord.com/invite/RGt5Ukw'
          target='_blank'
          rel='noopener noreferrer'
        >
          {' '}
          <img src={require('../../assets/yfu/discord-chat.png')} alt='' />
        </a>
      </div>
      <RoadMapSection />

      <div className='divided-3'></div>

      <WhiteListSection />
      <div className='divided-3'></div>
      <Faq />
      <div className='divided-4'></div>
      <TeamMembers />
      <div className='divided-5'></div>
      <YfuTokenSection account={account} />

      <footer className='footer'>
        <a
          href='https://discord.com/invite/RGt5Ukw'
          target='_blank'
          rel='noopener noreferrer'
        >
          <IoLogoDiscord />
        </a>
        <a
          href='https://animelimited.medium.com/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img src={require('../../assets/yfu/m-logo.png')} alt='medium logo' />
        </a>
        <a
          href='https://twitter.com/AnimeLtd'
          target='_blank'
          rel='noopener noreferrer'
        >
          <IoLogoTwitter />
        </a>
        <a
          href='https://www.youtube.com/channel/UCc-ETacfyUryGaXqeks_oSQ/featured'
          target='_blank'
          rel='noopener noreferrer'
        >
          <IoLogoYoutube />
        </a>
        <a
          href='https://www.instagram.com/animelimited.yfu'
          target='_blank'
          rel='noopener noreferrer'
        >
          <IoLogoInstagram />
        </a>
        <a
          href='https://www.tiktok.com/@animelimited'
          target='_blank'
          rel='noopener noreferrer'
        >
          <IoLogoTiktok />
        </a>
      </footer>
    </div>
  );
};

export default withNamespaces()(withRouter(Home));
