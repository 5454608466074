import React from 'react';
import './yfu-series.scss';

const YfuSeries = () => {
  return (
    <>
      <section className='yfu-series'>
        <div className='top'>
          <img src={require('../../../assets/v2/yfu-series/Pantalla5_texto.png')} alt='' />
        </div>
        <div className='center'>

          <span className='url-left'>
            <a href="https://labs.anime.limited" target="_blank" rel='noopener noreferrer' ><img src={require('../../../assets/v2/yfu-series/PlaceBidBtn.png')} alt="" /></a>
          </span>
          <span className='url-right'>
            <a href="https://mint.anime.limited" target="_blank" rel='noopener noreferrer'><img src={require('../../../assets/v2/yfu-series/MintBtn.png')} alt="" /></a>
          </span>
          <img src={require('../../../assets/v2/yfu-series/Pantalla6_Texto.png')} alt='' />
        </div>
      </section>
      <section className='yfu-girls'>
        <div className='down'>
          <img src={require('../../../assets/v2/yfu-series/Pantalla7_Texto.png')} alt='' />
        </div>
      </section>
    </>

  );
};

export default YfuSeries;
