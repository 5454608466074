import React from 'react';

import './faq-section.scss';
import { faqs } from '../../../utils/Texts';

import Question from './Question';

const Faq = () => {
  return (
    <section className='faq-section' id='faq'>
      <div className='section-container'>
        <div className='faq'>
          <div className='faq-rec-container'>
            <div className='faq-rec'>
              <img
                src={require('../../../../assets/yfu/welcome-rectangle.png')}
                alt=''
              />
              <p>FAQ</p>
            </div>
          </div>
          {faqs.map((faq, i) => (
            <Question key={i} question={faq.question} answer={faq.answer} />
          ))}
          {/* <Question question='What are some future aplications for YFU Girls?' an/>
          <Question question='How can I get a YFU Girl?' answer='bla bla' />
          <Question question='What will be the price of a YFU Girl?' />
          <Question question='How many YFU Girls can I purchase?' />
          <Question question='When will my YFU Girl be revealed?' />
          <Question question='What can I do with my YFU Girl?' />
          <Question question='How do I contact the Anime.Limited team?' /> */}
        </div>
      </div>
    </section>
  );
};

export default Faq;
