import React from 'react';
import SocialLinks from '../socialLinks/Index';

import './top-section.scss';

const TopSection = () => {
  return (
    <section className='top-section'>
      <div className='bg-top'>
        <img src={require("../../../assets/v2/top/Pantalla1_BGtext.png")} />
        <SocialLinks />
      </div>
      <div className='btn-mint'>
        <a href='https://mint.anime.limited/' alt='' target='_blank' rel='noopener noreferrer'><img src={require("../../../assets/v2/top/Pantalla1_MintYourYFU.png")} /></a>
      </div>
      <div className='logo-labs'>
        <a href='https://mint.anime.limited/' alt='' target='_blank' rel='noopener noreferrer'><img src={require("../../../assets/v2/top/logo-anime-labs.png")} /></a>
      </div>
      <div className='btn-bid'>
        <a href='https://labs.anime.limited/' alt='' target='_blank' rel='noopener noreferrer'><img src={require("../../../assets/v2/top/Pantalla1_PlaceBid.png")} /></a>
      </div>
    </section>
  );
};

export default TopSection;
