import React, { useState } from 'react';

import './hero-section.scss';
import { Texts } from '../../utils/Texts';
import CountDown from '../../utils/countDown';
import { Link } from 'react-scroll';

const HeroSection = () => {
  const [isCountDownEnd, setIsCountDownEnd] = useState(false);

  return (
    <div className='hero-section' id='hero'>
      <video autoPlay muted loop id='hero-bg-video'>
        <source
          src={require('../../../assets/media/YFUverse_BGloop_ANIME_LIMITED_website_BG.mp4')}
          type='video/mp4'
        />
        Your browser does not support HTML5 video.
      </video>

      <div className='hero-section-info'>
        <h1>YFU GIRLS</h1>

        <p>
          A collection of unique YFUS who live on the Ethereum blockchain.
        </p>
        <div className='hero-section-btn-container'>
          {/* {isCountDownEnd && (
            <button className='btn hero-section-btn'>
              {Texts.HERO_SECTION_ADDRESSES_ONLY}
            </button>
          )} */}
          <a
            className='btn hero-section-btn'
            href='https://mint.anime.limited'
            target="_blank"
          >
            MINT YOUR YFU GIRL
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
