export const Texts = {
  HERO_SECTION_WHITE_LIST_COUNTDOWN: 'White Mint Opens In:',
  HERO_SECTION_ADDRESSES_ONLY: 'WHITELISTED ADDRESS ONLY',
  HERO_SECTION_YFU_LEFT: 'YFU Girls Left:',
};

export const faqs = [
  {
    question: 'How can I get a YFU Girl?',
    answer:
      'You may be able to get a YFU Girl by forming part of the whitelist in our discord server or through minting on our webpage at the date of release!',
  },
  // {
  //   question: ' What will be the price of a YFU Girl?',
  //   answer:
  //     ' Minting a YFU Girl costs 0.05 ETH plus gas fees. Everyone will pay the same price.',
  // },
  {
    question: ' How many YFU Girls can I purchase?',
    answer:
      'A maximum of 30 YFU Girls will be allowed to mint per person at the time of whitelisting. After the whitelist period is over, there is no maximum amount that a single person can buy.',
  },
  {
    question: 'When will my YFU Girl be revealed?',
    answer:
      "You'll be able to see your YFU Girl on the OpenSea platform immediately after minting it.",
  },
  {
    question: 'What can I do with my YFU Girl?',
    answer:
      'Well… that depends ;)  But as far as we know, you will be able to get access to exclusive events, launches and promotions, as well as forming part of the Anime.Limited ecosystem which will evolve to bring amazing new future applications.',
  },
  {
    question: 'How do I contact the Anime.Limited team?',
    answer:
      'You may be able to contact Anime.Limited team through discord server and/or info@anime.limited',
  },
  {
    question: 'What are some future applications for YFU Girls?',
    answer:
      "Our community may help us by voting on this, but some of the possible applications we've discussed include the development of a platform through which the owner can interact with his YFU Girl through artificial intelligence, a kick start of new anime series, Memberships and exclusive benefits in streaming services etc…. A whole metaverse could be developed around the Anime. Limited ecosystem with your help.",
  },
];
