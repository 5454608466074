import React from 'react';
import { IoLogoDiscord, IoLogoMedium, IoLogoTwitter, IoLogoYoutube, IoLogoInstagram, IoLogoTiktok } from 'react-icons/io5';

import './social-links.scss';

const SocialLinks = () => {
  return (
    <section className='social-links'>
      <a
        href='https://discord.com/invite/RGt5Ukw'
        target='_blank'
        rel='noopener noreferrer'
      >
        <IoLogoDiscord />
      </a>
      <a
        href='https://animelimited.medium.com/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={require('../../../assets/yfu/m-logo.png')} alt='medium logo' />
      </a>
      <a
        href='https://twitter.com/AnimeLtd'
        target='_blank'
        rel='noopener noreferrer'
      >
        <IoLogoTwitter />
      </a>
      <a
        href='https://www.youtube.com/channel/UCc-ETacfyUryGaXqeks_oSQ/featured'
        target='_blank'
        rel='noopener noreferrer'
      >
        <IoLogoYoutube />
      </a>
      <a
        href='https://www.instagram.com/animelimited.yfu'
        target='_blank'
        rel='noopener noreferrer'
      >
        <IoLogoInstagram />
      </a>
      <a
        href='https://www.tiktok.com/@animelimited'
        target='_blank'
        rel='noopener noreferrer'
      >
        <IoLogoTiktok />
      </a>
    </section>
  );
};

export default SocialLinks;
