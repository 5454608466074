import React from 'react';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { Button, Col, Accordion, Row } from 'react-bootstrap';
import { BsChevronRight } from 'react-icons/bs';

const Faq = (props) => {
  return (
    <div className=''>
      <div className='mt-5 mt-sm-0 pt-5 p-sm-5 px-2'>
        <h4 className='orange'>ANIME LIMITED</h4>
        <h1>FREQUENTLY ASKED QUESTIONS</h1>

        <div className='p-sm-5 p-1'>
          <div className='row'>
            <div className='col-lg-9 col-md-10 col-sm-12'>
              {/* defaultActiveKey='0' */}
              <Accordion>
                <div className='faq-item'>
                  <div>
                    <Accordion.Toggle as={Button} variant='' eventKey='0'>
                      <BsChevronRight /> What is Anime Limited?
                    </Accordion.Toggle>
                  </div>
                  <Accordion.Collapse eventKey='0' className='pl-4'>
                    <div className='py-3'>
                      Anime Limited (formerly known as YFU finance) is a DeFi
                      community proyect whose goal is to become the biggest
                      Crypto/Anime community in the world by satisfying todays
                      needs through their fair $YFU token ecosystem.
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className='faq-item'>
                  <div>
                    <Accordion.Toggle as={Button} variant='' eventKey='1'>
                      <BsChevronRight /> What is the maximum supply of $YFU
                      tokens?
                    </Accordion.Toggle>
                  </div>
                  <Accordion.Collapse eventKey='1' className='pl-4'>
                    <div className='py-3'>
                      12,400,000 is $YFU tokens maximum supply
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className='faq-item'>
                  <div>
                    <Accordion.Toggle
                      as={Button}
                      variant=''
                      eventKey='2'
                      className='text-left'
                    >
                      <BsChevronRight /> What wallet should I use for my $YFU
                      tokens?
                    </Accordion.Toggle>
                  </div>
                  <Accordion.Collapse eventKey='2' className='pl-4'>
                    <div className='py-3'>
                      $YFU is based on the ethereum blockchain, therefore any
                      ethereum capable wallet should work, however we currently
                      recommend Metamask.
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className='faq-item'>
                  <div>
                    <Accordion.Toggle as={Button} variant='' eventKey='3'>
                      <BsChevronRight /> Is it safe to invest in $YFU?
                    </Accordion.Toggle>
                  </div>
                  <Accordion.Collapse eventKey='3' className='pl-4'>
                    <div className='py-3'>
                      Investing in DeFi in general is definitely a secure market
                      for veteran cryptocurrency traders, however we promote for
                      any level of proficiency to do your own research and
                      proceed with caution.
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className='faq-item'>
                  <div>
                    <Accordion.Toggle as={Button} variant='' eventKey='4'>
                      <BsChevronRight /> What is DeFi?
                    </Accordion.Toggle>
                  </div>
                  <Accordion.Collapse eventKey='4' className='pl-4'>
                    <div className='py-3'>
                      DeFi or “Decentralized Finance” is a blockchain based form
                      of finance which uses smart contracts instead of relying
                      on centralized financial intermediaries such as banks.
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className='faq-item'>
                  <div>
                    <Accordion.Toggle as={Button} variant='' eventKey='5'>
                      <BsChevronRight /> Will there be $YFU public sales?
                    </Accordion.Toggle>
                  </div>
                  <Accordion.Collapse eventKey='5' className='pl-4'>
                    <div className='py-3'>
                      No, $YFU will not have public sales.
                    </div>
                  </Accordion.Collapse>
                </div>
                <div className='faq-item'>
                  <div>
                    <Accordion.Toggle as={Button} variant='' eventKey='6'>
                      <BsChevronRight /> Did Anime Limited have a $YFU pre-mine?
                    </Accordion.Toggle>
                  </div>
                  <Accordion.Collapse eventKey='6' className='pl-4'>
                    <div className='py-3'>
                      No, there was no $YFU pre-mine as it is a fair
                      distribution token.
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <img
        src={require('../../assets/yfu/woman.png')}
        className='fixed-bottom left-auto max-width-25'
        alt=''
      />
    </div>
  );
};

export default withNamespaces()(withRouter(Faq));
