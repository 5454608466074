import React from 'react';

import './team.scss';

const Team = () => {
  return (
    <section className='team'>
      <div className='top'>
        <img src={require('../../../assets/v2/team/Pantalla_Team.png')} alt='' />
      </div>
      <div className='down'>
        <a href='https://forms.gle/mMH6shgzdRhBMiyy6' target='_blank' rel='noopener noreferrer'> <img src={require('../../../assets/v2/team/Pantalla_Team_Boton.png')} alt='' /></a>
      </div>
    </section>
  );
};

export default Team;
