import React from 'react';

const TeamMemberCard = ({ name, desc, img }) => {
  return (
    <div className='tm-card'>
      <img
        src={img || require('../../../../assets/yfu/tm-no-img.png')}
        alt={name}
      />

      <div className='tm-card-info'>
        <p>{name}</p>
        <p>{desc}</p>
      </div>
    </div>
  );
};

export default TeamMemberCard;
