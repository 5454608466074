import axios from 'axios';
import serialize from './serialize';

let raribleUrl =
  'https://api.rarible.com/protocol/v0.1/ethereum/nft/items/byCreator?';
const query = {
  size: 50,
  creator: '0xefc8874d5ac59a2dd57d68374df76e856c99eb2c',
  includeMeta: true,
};

const config = {
  method: 'get',
  url: raribleUrl,
  headers: {},
  // data,
};

const getCollection = async (creatorAddress) => {
  if (creatorAddress) query.creator = creatorAddress;
  config.url = raribleUrl + serialize(query);

  try {
    let res = await axios(config);
    console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export default getCollection;
