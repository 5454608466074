import React from 'react';

import './chart-section.scss';

const ChartSection = () => {
  return (
    <section className='chart-section'>
      <div className='section-container'>
        <div className='chart'>
          <div className='chart-left'>
            <p>
              Your <strong>YFU Girl</strong> serves as your membership card, and
              grants access to benefits like giveaways, exclusive channels and
              voting.
            </p>
            <p>
              All <strong>YFU Girls</strong> are cool, but some are rarer than
              others.
            </p>
            {/* <small>
              Purchasing a YFU Girl costs 0.05 ETH.Everyone will pay the same
              price.
            </small> */}
          </div>

          <div className='chart-right'>
            <img
              src={require('../../../../assets/yfu/chart-home.png')}
              alt=''
            />
          </div>
        </div>
      </div>
    </section>
    // <section className='chart-section'>
    //   <div className='section-container'>
    //     <div className='section-container-left'>
    //       <div className='section-description'>
    // <p>
    //   Your <strong>YFU Girl</strong> serves as your membership card, and
    //   grants access to benefits like giveaways, exclusive channels and
    //   voting.
    // </p>
    // <p>
    //   All <strong>YFU Girls</strong> are cool, but some are rarer than
    //   others.
    // </p>
    // <small>
    //   Purchasing a YFU Girl costs 0.05 ETH.Everyone will pay the same
    //   price.
    // </small>
    //       </div>
    //     </div>

    //     <div className='section-container-rigth'>
    //       <img src={require('../../../../assets/yfu/chart-home.png')} alt='' />
    //     </div>
    //   </div>
    // </section>
  );
};

export default ChartSection;
