import React, { Component } from 'react';

import { Switch, Route } from 'react-router-dom';
import IpfsRouter from 'ipfs-react-router';
import Leftnav from './components/leftnav/leftnav';
import Header from './components/header/header';
import Exchange from './components/exchange/exchange';

import './i18n';

import Account from './components/account/account';
import Home from './components/home/home';
import Hives from './components/hives/hives';
import Faq from './components/faq/faq';
import Resources from './components/resources/resources';
import Stake from './components/stake/stake';
import Nft from './components/nft/nft';
import NftDetails from './components/nft/nftDetails';
import Pools from './components/pools/pools';
import ScrollToTop from './components/utils/ScrollToTop';
import BiosTeam from './components/biosTeam/Index';
import './App.css'

import {
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  CONFIGURE,
  CONFIGURE_RETURNED,
  GET_BALANCES_PERPETUAL,
  GET_BALANCES_PERPETUAL_RETURNED,
} from './constants';

import { injected } from './stores/connectors';

import Store from './stores/store';
import TopSection from './components/v2/topSection/Index';
import AboutUs from './components/v2/aboutUs/Index';
import YfuSeries from './components/v2/yfu-series/Index';
import FaqV2 from './components/v2/faq/Index';
import Team from './components/v2/team/Index';

const { emitter, dispatcher, store } = Store;

class App extends Component {
  state = {
    account: null,
    headerValue: null,
    themeType: false,
    style: 'dark-mode',
  };

  setHeaderValue = (newValue) => {
    this.setState({ headerValue: newValue });
  };

  componentWillMount() {
    emitter.on(CONNECTION_CONNECTED, this.connectionConnected);
    emitter.on(CONNECTION_DISCONNECTED, this.connectionDisconnected);
    emitter.on(CONFIGURE_RETURNED, this.configureReturned);
    emitter.on(GET_BALANCES_PERPETUAL_RETURNED, this.getBalancesReturned);

    let style = localStorage.getItem('theme');
    if (style) this.setState({ style });

    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        injected
          .activate()
          .then((a) => {
            store.setStore({
              account: { address: a.account },
              web3context: { library: { provider: a.provider } },
            });
            emitter.emit(CONNECTION_CONNECTED);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        injected.getProvider().then((p) => {
          store.setStore({
            account: { address: '0x502288E236285C59193b6aDf896531B407a61736' },
            web3context: { library: { provider: p } },
          });
        })
          .catch((e) => {
            console.log(e);
          });
        emitter.emit(CONNECTION_CONNECTED);
      }
    });
  }

  componentWillUnmount() {
    emitter.removeListener(CONNECTION_CONNECTED, this.connectionConnected);
    emitter.removeListener(
      CONNECTION_DISCONNECTED,
      this.connectionDisconnected
    );
    emitter.removeListener(CONFIGURE_RETURNED, this.configureReturned);
    emitter.removeListener(
      GET_BALANCES_PERPETUAL_RETURNED,
      this.getBalancesReturned
    );
  }

  getBalancesReturned = () => {
    window.setTimeout(() => {
      dispatcher.dispatch({ type: GET_BALANCES_PERPETUAL, content: {} });
    }, 300000);
  };

  configureReturned = () => {
    dispatcher.dispatch({ type: GET_BALANCES_PERPETUAL, content: {} });
  };

  connectionConnected = () => {
    this.setState({ account: store.getStore('account') });
    dispatcher.dispatch({ type: CONFIGURE, content: {} });
  };

  connectionDisconnected = () => {
    this.setState({ account: store.getStore('account') });
  };

  onSwitchThemeHandler = () => {
    let style = this.state.style == 'light-mode' ? 'dark-mode' : 'light-mode';
    localStorage.setItem('theme', style);
    this.setState({ style });
  };

  render() {
    const { account } = this.state;

    return (
      <IpfsRouter>
        <ScrollToTop />
        <Header />
        <Leftnav
          onSwitchTheme={this.onSwitchThemeHandler}
          activeStyle={this.state.style}
        />
        <TopSection />
        <div className='bg-purple'>
          <AboutUs />
          <YfuSeries />
          <Team />
          <Exchange />
          <FaqV2 />
        </div>
        {/* <Switch> */}
        {/* <Route path='/stake/:address' component={account ? Stake : Account} />
          <Route path='/hives' component={account ? Hives : Account} />
          <Route path='/faq' component={Faq} />
          <Route path='/exchange' component={account ? Exchange : Account} />
          <Route path='/resources' component={Resources} />
          <Route exact path='/pools/:selectedPool'>
            {account && (
              <Pools
                assetsStoreKey='exchangeAssets'
                extraAssets={['ETH', 'WPE']}
                disableSwap={true}
              />
            )}
          </Route>
          <Route path='/pools'>
            {account && (
              <Pools
                assetsStoreKey='exchangeAssets'
                extraAssets={['ETH', 'WPE']}
                disableSwap={true}
              />
            )}
          </Route>
          <Route path='/biosTeam'>
            <BiosTeam />
          </Route>
          <Route
            exact
            path='/nft/:tokenAddress/:tokenId'
            component={NftDetails}
          />
          <Route exact path='/nft/:tokenAddress' component={Nft} />
          <Route path='/nft'>
            <Nft />
          </Route>
          <Route path='/'>
            <Home account={account} />
          </Route> */}
        {/* </Switch> */}
      </IpfsRouter>
    );
  }
}

export default App;
