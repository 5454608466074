import React from 'react';

import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';

import './header.scss';

import UnlockModal from '../unlock/unlockModal.jsx';
import {
  ERROR,
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  CONFIGURE_RETURNED,
} from '../../constants';
import { IconContext } from 'react-icons';
import { AiOutlineMenu } from 'react-icons/ai';

import Store from '../../stores/store';
const { emitter, store } = Store;

class Header extends React.Component {
  constructor(props) {
    super(props);
    const account = store.getStore('account');
    const themeType = store.getStore('themeType');
    const activeClass = store.getStore('activeClass');
    this.headerRef = React.createRef();
    this.state = {
      activeClass,
      loading: false,
      account: account,
      assets: store.getStore('assets'),
      modalOpen: false,
      themeType: themeType,
      isSticky: false,
    };
  }

  componentWillMount() {
    emitter.on(ERROR, this.errorReturned);
    emitter.on(CONNECTION_CONNECTED, this.connectionConnected);
    emitter.on(CONNECTION_DISCONNECTED, this.connectionDisconnected);
    emitter.on(CONFIGURE_RETURNED, this.configureReturned);
  }

  componentWillUnmount() {
    const cachedRef = this.headerRef.current,
      observer = new IntersectionObserver(
        ([e]) => this.setState({ isSticky: e.intersectionRatio < 1 }),
        { threshold: [1] }
      );

    emitter.removeListener(ERROR, this.errorReturned);
    emitter.removeListener(CONNECTION_CONNECTED, this.connectionConnected);
    emitter.removeListener(
      CONNECTION_DISCONNECTED,
      this.connectionDisconnected
    );
    emitter.removeListener(CONFIGURE_RETURNED, this.configureReturned);

    observer.unobserve(cachedRef);
  }

  connectionConnected = () => {
    this.setState({ account: store.getStore('account') });
  };

  configureReturned = () => {
    //.this.props.history.push('/')
  };

  connectionDisconnected = () => {
    this.setState({ account: store.getStore('account'), loading: false });
  };

  errorReturned = (error) => {
    //TODO: handle errors
  };

  componentDidUpdate = () => {
    console.log(this.state.isSticky);
  };

  renderModal = () => {
    return (
      <UnlockModal
        closeModal={this.closeModal}
        modalOpen={this.state.modalOpen}
      />
    );
  };

  unlockClicked = () => {
    this.setState({ modalOpen: true, loading: true });
  };

  closeModal = () => {
    this.setState({ modalOpen: false, loading: false });
  };

  render() {
    const { account, modalOpen } = this.state;
    var address = null;
    if (account.address) {
      address =
        account.address.substring(0, 6) +
        '...' +
        account.address.substring(
          account.address.length - 4,
          account.address.length
        );
    }
    return (
      <>
        <nav
          className={`${this.state.isSticky ? 'stuckHeader' : 'unStuckHeader'
            } navbar navbar-expand-lg header`}
          ref={this.headerRef}
        >
          <div className='nav-left'>
            <span>
              <span
                className='d-block d-lg-none btn'
                id='header-burger-menu-icon'
              >
                <IconContext.Provider value={{ size: '2em' }}>
                  <AiOutlineMenu
                    data-bs-toggle='offcanvas'
                    data-bs-target='#mobileSideBar'
                    aria-controls='mobileSideBar'
                  />
                </IconContext.Provider>
              </span>
            </span>
            <div className='left-items'>
              <NavLink to='top-section'>
                <img
                  src={require('../../assets/yfu/anime-limited.png')}
                  alt='yfu logo'
                  className='nav-logo'
                />
              </NavLink>
              <div className='nav-items'>
                <ul>
                  <li>
                    <Link to='about-us' activeClass='active'>
                      <img
                        src={require('../../assets/v2/header/Bubble_AboutUs.png')}
                        alt='About Us'
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to='yfu-series' activeClass='active'>
                      <img
                        src={require('../../assets/v2/header/Bubble_YFUCast.png')}
                        alt='YFU Cast'
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to='yfu-girls' activeClass='active'>
                      <img
                        src={require('../../assets/v2/header/Bubble_YFUGirls.png')}
                        alt='YFU Girls'
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to='exchange' activeClass='active'>
                      <img
                        src={require('../../assets/v2/header/Bubble_BuyToken.png')}
                        alt='Buy Token'
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to='faq' activeClass='active'>
                      <img
                        src={require('../../assets/v2/header/Bubble_faq.png')}
                        alt='FAQ'
                      />
                    </Link>
                  </li>
                  {/* <li>
                    <Link to='yfuToken' activeClass='active'>
                      $YFU Token
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>

          <div>
            {/* {address && (
              <button
                className='btn navigator-unlock-btn'
                onClick={this.unlockClicked}
              >
                <span className='mr-5'>
                  <b>Wallet</b>
                </span>
                <span>{address}</span>
              </button>
              // <Link to='/#' onClick={this.unlockClicked}>
              //   <span className='text-purple'>{address}</span>
              // </Link>
            )}
            {!address && (
              <button
                className='btn navigator-unlock-btn'
                onClick={this.unlockClicked}
              >
                <p className='mb-0'>CONNECT WALLET</p>
              </button>
            )} */}
            {/* <div className='d-flex justify-content-between header-social mt-2'>
              <a href='https://twitter.com/AnimeLtd' target='_blank'>
                <img src={require('../../assets/yfu/social-twitter.png')} />
              </a>
              <a href='https://t.me/animeltd' target='_blank'>
                <img src={require('../../assets/yfu/social-telegram.png')} />
              </a>
              <a href='https://animelimited.medium.com/' target='_blank'>
                <img src={require('../../assets/yfu/social-medium.png')} />
              </a>
              <a
                href='https://www.instagram.com/AnimeLimited.Yfu/'
                target='_blank'
              >
                <img src={require('../../assets/yfu/social-instagram.png')} />
              </a>
              <a href='https://discord.com/invite/RGt5Ukw' target='_blank'>
                <img src={require('../../assets/yfu/social-discord.png')} />
              </a>
            </div> */}
          </div>
          {/* <Navbar.Collapse className='justify-content-end'>
          </Navbar.Collapse> */}
        </nav>

        {modalOpen && this.renderModal()}
      </>
    );
  }
}

export default Header;
