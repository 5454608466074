import React from 'react';

import BiosTeamCard from './Card/Index';

const BiosTeam = () => {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12'>
          <h1 className='orange'>BIOS TEAM</h1>
        </div>
      </div>
      {/* require('../../assets/logo/blue&gray.png').default */}
      <div className='row'>
        <div className='col-12 col-md-6 col-xl-4'>
          <BiosTeamCard
            image={require('../../assets/bios-team-images/Guro.png')}
            name='Guro'
            description='Master of Public relations. With a great vision and sense of architecture. Loves to expand the community. With a great sense of humor. '
          />
        </div>
        <div className='col-12 col-md-6 col-xl-4'>
          <BiosTeamCard
            image={require('../../assets/bios-team-images/Nigiri.png')}
            name='Nigiri'
            description='A jack of all trades but a master of none is oftentimes better than a master of one. Community builder and crypto enthusiast steadily working towards creating a prosper and fun community.'
          />
        </div>
        <div className='col-12 col-md-6 col-xl-4'>
          <BiosTeamCard
            name='Ashtar'
            image={require('../../assets/bios-team-images/Ashtar.png')}
            description='Wizard of illusion, content creator blending different mediums of art into one, making each piece a masterpiece.  Specialist on storytelling using different 2D and 3D mediums of art.'
          />
        </div>
        <div className='col-12 col-md-6 col-xl-4'>
          <BiosTeamCard
            name='ShinRin'
            image={require('../../assets/bios-team-images/Shinrin.png')}
            description='Master in connecting people. Plants, animals, humans and  trees spokesperson. Love the outdoors and SinrinYoku practices (aka bath wood).'
          />
        </div>
        <div className='col-12 col-md-6 col-xl-4'>
          <BiosTeamCard
            name='Tokatl'
            image={require('../../assets/bios-team-images/Tokatl.png')}
            description='Master of computers and electronics. Loves to create new interactions between reality and digital sources.'
          />
        </div>
        <div className='col-12 col-md-6 col-xl-4'>
          <BiosTeamCard
            name='Karasu'
            image={require('../../assets/bios-team-images/Karasu.png')}
            description='Always using his sharp and powerful foresight to innovate and find the best technologies.'
          />
        </div>
      </div>
    </div>
  );
};

export default BiosTeam;
