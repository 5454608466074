import React from 'react';

import './white-list-section.scss';

const WhiteListSection = () => {
  return (
    <section className='white-list-section' id='whitelist'>
      <div className='section-container'>
        <div className='white-list'>
          <div className='white-list-rec-container'>
            <div className='white-list-rec'>
              <img
                src={require('../../../../assets/yfu/welcome-rectangle.png')}
                alt=''
              />
              <p>WHITELIST</p>
            </div>
          </div>

          <div className='white-list-left'>
            <div className='left-container'>
              <p>
                <strong>Join the whitelist!</strong>
                <br />
                Active and engaged members of the community will be able to mint
                YFU Girls first before public release.
              </p>
              <p>
                Don’t miss this opportunity and join following these simple
                steps!
              </p>
            </div>
          </div>

          <div className='white-list-right'>
            <div className='step'>
              <h5>STEP 1</h5>
              <p>Join our Discord Server.</p>
              <a
                href='https://discord.com/invite/RGt5Ukw'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={require('../../../../assets/join-discord-btn.png')}
                  alt=''
                />
              </a>
            </div>
            <div className='step'>
              <h5>STEP 2</h5>
              <p>
                Generate your own server invite and bring 10 crypto/anime
                enthusiasts onboard! No maximum.
              </p>
            </div>
            <div className='step'>
              <h5>STEP 3</h5>
              <p>
                Once you’re set, you’ll grant access to exclusive whitelist
                channels.
              </p>
            </div>
            <div className='step'>
              <h5>STEP 4</h5>
              <p>
                There you’ll be able to send the !join command to connect your
                wallet. That will add it to the whitelist, allowing you to mint
                before anyone else.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhiteListSection;
