import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaThLarge } from 'react-icons/fa';
import { WiMoonAltThirdQuarter } from 'react-icons/wi';
import { IconContext } from 'react-icons';
import { Link } from 'react-scroll';
import { AiOutlineTeam } from 'react-icons/ai';

const LeftNav = (props) => {
  const menuList = (
    <ul>
      <NavLink exact data-bs-dismiss='offcanvas' to='top-section'>
        <li className='item-menu navbrand-img'>
          <img alt='' src={require('../../assets/yfu/anime-limited.png')} />
        </li>
      </NavLink>

      {/* <NavLink exact data-bs-dismiss='offcanvas' to='/'>
        <li className='item-menu'>
          <img
            alt=''
            src={require('../../assets/yfu/yfu-white.png')}
            width='20'
          />
          <span className='menu'>YFU TOKEN</span>
        </li>
      </NavLink>

      <NavLink exact data-bs-dismiss='offcanvas' to='/nft'>
        <li className='item-menu'>
          <img
            alt=''
            src={require('../../assets/yfu/ln-compass-white.png')}
            width='20'
          />
          <span className='menu'>OUR NFTs</span>
        </li>
      </NavLink>

      <NavLink exact data-bs-dismiss='offcanvas' to='/hives'>
        <li className='item-menu'>
          <img
            alt=''
            src={require('../../assets/yfu/ln-corn-white.png')}
            width='20'
          />
          <span className='menu'>POOLS</span>
        </li>
      </NavLink>

      <NavLink exact data-bs-dismiss='offcanvas' to='/exchange'>
        <li className='item-menu'>
          <img alt='' src={require('../../assets/exchange.png')} width='20' />
          <span className='menu'>Exchange</span>
        </li>
      </NavLink>

      <NavLink exact data-bs-dismiss='offcanvas' to='/faq'>
        <li className='item-menu'>
          <img
            alt=''
            src={require('../../assets/yfu/ln-faq-white.png')}
            width='20'
          />
          <span className='menu'>FAQ</span>
        </li>
      </NavLink>

      <NavLink exact data-bs-dismiss='offcanvas' to='/resources'>
        <li className='item-menu'>
          <img
            alt=''
            src={require('../../assets/yfu/ln-resources-white.png')}
            width='20'
          />
          <span className='menu'>RESOURCES</span>
        </li>
      </NavLink>

      <NavLink exact data-bs-dismiss='offcanvas' to='/biosTeam'>
        <li className='item-menu'>
          <AiOutlineTeam size={22} />
          <span className='menu'>BIOS TEAM</span>
        </li>
      </NavLink> */}
      <li>
        <Link to='about-us' data-bs-dismiss='offcanvas' activeClass='active'>
          <img
            src={require('../../assets/v2/header/Bubble_AboutUs.png')}
            alt='About Us'
          />
        </Link>
      </li>
      <li>
        <Link to='yfu-series' data-bs-dismiss='offcanvas' activeClass='active'>
          <img
            src={require('../../assets/v2/header/Bubble_YFUCast.png')}
            alt='YFU Cast'
          />
        </Link>
      </li>
      <li>
        <Link to='yfu-girls' data-bs-dismiss='offcanvas' activeClass='active'>
          <img
            src={require('../../assets/v2/header/Bubble_YFUGirls.png')}
            alt='YFU Girls'
          />
        </Link>
      </li>
      <li>
        <Link to='exchange' data-bs-dismiss='offcanvas' activeClass='active'>
          <img
            src={require('../../assets/v2/header/Bubble_BuyToken.png')}
            alt='FAQ'
          />
        </Link>
      </li>
      <li>
        <Link to='faq' data-bs-dismiss='offcanvas' activeClass='active'>
          <img
            src={require('../../assets/v2/header/Bubble_faq.png')}
            alt='FAQ'
          />
        </Link>
      </li>
    </ul>
  );
  return (
    <>
      <div className='sidebar d-none d-lg-block '>
        <div className='leftNav sidbar-sticky'>{menuList}</div>
      </div>

      <div
        className='offcanvas offcanvas-start'
        tabIndex='-1'
        id='mobileSideBar'
        aria-labelledby='mobileSideBarLabel'
      >
        {/* <div className='offcanvas-header'>
          <h5 className='offcanvas-title' id='mobileSideBarLabel'>
            YFU SOCIAL
          </h5>
          <button
            type='button'
            className='btn-close text-reset'
            data-bs-dismiss='offcanvas'
            aria-label='Close'
          ></button>
        </div> */}
        {/* <div className='offcanvas-body'> */}
        <div className='leftNav'>{menuList}</div>
        {/* </div> */}
      </div>
    </>
  );
};

export default LeftNav;
