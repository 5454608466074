import React, { useState } from 'react';

import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const Question = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='faq-question-container' onClick={() => setIsOpen(!isOpen)}>
      <div className='question'>
        <p>{question}</p>

        <div className='question-icon'>
          {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </div>
      </div>
      {isOpen && (
        <div className='answer'>
          <p>{answer || 'answer here'}</p>
        </div>
      )}
    </div>
  );
};

export default Question;
