import React from 'react';

import './welcome-section.scss';

const WelcomeSection = () => {
  return (
    <section className='welcome-section'>
      <div className='section-container'>
        <div className='welcome'>
          <div className='welcome-rec-container'>
            <div className='welcome-rec'>
              <img
                src={require('../../../../assets/yfu/welcome-rectangle.png')}
                alt=''
              />
              <p>WELCOME</p>
            </div>
          </div>

          <div className='welcome-left'>
            <p>
              In early 2020, an explosion at the ERAL (European Radiation
              Accelerator Laboratory) unleashed thousands of YFUs into our
              Universe (which we now call our YFUverse) and opened a portal to
              other infinite “YFUverses”, some of which are extremely rare. It
              is believed that whomever owns YFU NFTs will be able to meet these
              fascinating, hyper-sexual creatures in their dreams, and some
              people say they will communicate telepathically sending constant
              naughty thoughts to their owners as their primary mission is to
              reproduce and preserve their species.
            </p>
            <a
              href='https://discord.com/invite/RGt5Ukw'
              target='_blank'
              rel='noopener noreferrer'
            >
              Read full Story
            </a>
          </div>

          <div className='welcome-right'>
            <div className='nft-img'>
              <img
                src={require('../../../../assets/yfu/YFU_1.png')}
                alt='YFU 3500'
              />
              <p>YFU #3500</p>
            </div>
            <div className='nft-img'>
              <img
                src={require('../../../../assets/yfu/YFU_2.png')}
                alt='YFU 3500'
              />
              <p>YFU #2200</p>
            </div>
            <div className='nft-img'>
              <img
                src={require('../../../../assets/yfu/YFU_3.png')}
                alt='YFU 3500'
              />
              <p>YFU #8120</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    // <section className='welcome-section'>
    //   <div className='section-container'>
    //     <div className='section-container-left'>
    //       <div className='welcome-rec-container'>
    //         <div className='welcome-rec'>
    //           <img
    //             src={require('../../../../assets/yfu/welcome-rectangle.png')}
    //             alt=''
    //           />

    //           <p>WELCOME</p>
    //         </div>
    //       </div>

    //       <div className='welcome-description'>
    // //         <p>
    // //           Mutant Cats is a collection of 9,999 colorful avatar-style NFTs.
    // //           These aren’t your average internet-beloved kittens, though.
    // //           Following a mysterious disease, all of the cats suffered from
    // //           genetic mutations. As a result, we have an impressive collection
    // //           of one-eyed, radioactive, or even green cats wearing unique
    // //           accessories.
    // //         </p>
    //       </div>
    //     </div>

    //     <div className='section-container-rigth'>
    // <div className='nft-img'>
    //   <img
    //     src={require('../../../../assets/yfu/YFU_1.png')}
    //     alt='YFU 3500'
    //   />
    //   <p>YFU #3500</p>
    // </div>
    // <div className='nft-img'>
    //   <img
    //     src={require('../../../../assets/yfu/YFU_2.png')}
    //     alt='YFU 3500'
    //   />
    //   <p>YFU #2200</p>
    // </div>
    // <div className='nft-img'>
    //   <img
    //     src={require('../../../../assets/yfu/YFU_3.png')}
    //     alt='YFU 3500'
    //   />
    //   <p>YFU #8120</p>
    // </div>
    //     </div>
    //   </div>
    // </section>
  );
};

export default WelcomeSection;
