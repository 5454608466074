import React from 'react';

import './roadMapSection.scss';
import RoadMapItem from './RoadMapItem';
import { Link } from 'react-scroll';

const RoadMapSection = () => {
  return (
    <section className='road-map-section' id='roadmap'>
      <div className='section-container'>
        <div className='road-map'>
          <div className='road-map-rec-container'>
            <div className='road-map-rec'>
              <img
                src={require('../../../../assets/yfu/welcome-rectangle.png')}
                alt=''
              />
              <p>ROADMAP</p>
            </div>
          </div>
          <div className='road-map-items'>
            <div className='road-map-header'>
              <small>
                Note: 1% of YFU Girls are being withheld from the sale. These
                will be used for giveaways, community members and the team.
              </small>
            </div>
            <RoadMapItem
              title='YFU GIRLS LAUNCH'
              description='Unique pieces of digital artwork generated from a mix of 300 different
             traits.'
              extra={true}
              className='fi'
            />
            <RoadMapItem
              title='WHITELIST'
              customDescription={
                <p>
                  We will allow <strong>whitelisting</strong>, so that those who
                  support the community may get a YFU Girl before anyone else.
                </p>
              }
              extraInfo={<Link to='whitelist'>How can i get whitelisted?</Link>}
            />
            <RoadMapItem
              title='MINT'
              description='
              After 20 days, minting will be available.'
            />
            <RoadMapItem
              title='AIRDROPS'
              customDescription={
                <p>
                  Through out the minting process, we’ll do exclusive airdrops
                  of <strong>10 YFU Girls</strong> to the token or NFT holders.
                </p>
              }
            />
            <RoadMapItem
              title={'VOTING'}
              description='Once the minting process ends, your YFU girl/s will grant you voting powers for future applications and projects.'
            />
            <RoadMapItem
              title='FUTURE APLICATIONS'
              description='FUTURE APLICATIONS
              Concepts like the first release of an anime series, to ways to interact with your YFU through artificial intelligence. These decisions will remain in the hands of those who have governance tokens.'
              extra={true}
            />
          </div>
        </div>
      </div>
    </section>
    // <section className='road-map-section'>
    //   <div className='section-container'>
    //     <div className='time-line-container'>
    //       <div className='road-map-left'>
    //         <div className='road-map-rec-container'>
    //           <div className='road-map-rec'>
    //             <img
    //               src={require('../../../../assets/yfu/welcome-rectangle.png')}
    //               alt=''
    //             />
    //             <p>ROADMAP</p>
    //           </div>
    //         </div>

    //         <small className='first-date'>Nov 1st 00:00 UTC</small>
    //         <small className='second-date'>TBD</small>
    //       </div>
    //       <div className='road-map-right'>
    //         <div className='road-map-header'>
    //   <small>
    //     Note: 1% of YFU Girls are being withheld from the sale. These
    //     will be used for giveaways, community members and the team.
    //   </small>
    // </div>

    // <div className='road-map-items'>
    //   <RoadMapItem
    //     title='YFU GIRLS LAUNCH'
    //     description='Unique pieces of digital artwork generated from a mix of 300 different
    //  traits.'
    //     extra={
    //       <img
    //         src={require('../../../../assets/yfu/arrow-right.png')}
    //         alt=''
    //       />
    //     }
    //   />
    //   <RoadMapItem
    //     title='WHITELIST'
    //     description='We will allow whitelisting, so that those who support the community may get a YFU Girl before anyone else.'
    //     extraArrow={
    //       <img
    //         src={require('../../../../assets/yfu/road-item-row.png')}
    //         alt=''
    //         className='extra-arrow'
    //       />
    //     }
    //     extraInfo='How can I get whitelisted?'
    //   />
    //   <RoadMapItem
    //     title='MINT'
    //     description='
    //   After 20 days, minting will begin at a price of 0.05 ETH. '
    //   />
    //   <RoadMapItem
    //     title='AIRDROPS'
    //     description='
    //   Through out the minting process, we’ll do exclusive airdrops of 10 YFU Girls to the token or NFT holders.'
    //   />
    //   <RoadMapItem
    //     title={'STAKING & VOTING'}
    //     description='
    //   Staking YFU Girls will grant voting powers, and reward holders with $YFU Token. '
    //   />
    //   <RoadMapItem
    //     title='FUTURE APLICATIONS'
    //     description='
    //   There are many plans for future applications and uses of the project, things like the first release of an anime, to ways to interact with your YFU through artificial intelligence. These decisions will remain in the hands of those who have governance tokens.'
    //     extra={
    //       <img
    //         src={require('../../../../assets/yfu/arrow-right.png')}
    //         alt=''
    //       />
    //     }
    //   />
    // </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
  );
};

export default RoadMapSection;
{
  /* <div className='section-container-left'>
          <div className='time-line-container'>
          
          </div>
        </div>
        <div className='section-container-rigth'></div>
      */
}
//

//
