import React from 'react';

const RoadMapItem = ({
  title,
  description,
  extra,
  extraArrow,
  customDescription,
  extraInfo,
  className = '',
}) => {
  return (
    <>
      <div className={`${className} road-map-item`}>
        {extra ? (
          extra
        ) : (
          <img
            src={require('../../../../assets/yfu/arrow-down.png')}
            alt=''
            className='arrow-down'
          />
        )}
        <h5>{title}</h5>
        {description && <p>{description}</p>}
        {customDescription && customDescription}
        {extraArrow && extraArrow}
        {extraInfo && (
          <div className='extra-info'>
            <div className='extra-info-container'>{extraInfo}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default RoadMapItem;
// YFU GIRLS LAUNCH

// Unique pieces of digital artwork generated from a mix of 300 different
// traits.
