import { Link } from '@material-ui/core';
import React from 'react';
import Accordion from '../accordion/Index';

import './faq.scss';

const FaqV2 = () => {
  return (
    <section className='faq'>
      <div className='title'>
        <img src={require('../../../assets/v2/faq/faq.png')} alt=''></img>
      </div>
      <div className='panel'>
        <Accordion
          title="Which episodes can I cast my YFU in?"
          text="Casting bids will only be available for the upcoming episode."
          align='left'
        />
        <Accordion
          title="When does the first Auction start?"
          text="Official Dates will be released on our social media platforms, stay tuned."
          align='right'
        />
        <Accordion
          title="How long will the auctions be open?"
          text="Auction will be active for a 72 hour period."
          align='left'
        />
        <Accordion
          title="How many bids can I place?"
          text="You can only have an active bid per YFU. You will have to decide which YFU you want to cast and bid upon her for the desired role in the episode. If your YFU is currently winning the bid, she will no longer be available to be casted for another role. However, someone could always come and take your spot, prepare accordingly 😉"
          align='right'
        />
        <Accordion
          title="Can I use different YFU´s to place a bid?"
          text="You can only have an active bid per YFU, however, if someone comes and takes your role, you can always bid back with the same YFU or refresh your strategy with a different one."
          align='left'
        />
        <Accordion
          title="How will I know if my YFU won the auction?"
          text="Results will be shown on the Auction website once the auction has concluded."
          align='right'
        />
        <Accordion
          title="Where will I visualize the auction's current status?"
          text="Bidding history and current status for the current Role in the episode will be displayed on the Auction page."
          align='left'
        />
        <Accordion
          title="When will the next episode be released?"
          text="3 weeks after the completion of the auction."
          align='right'
        />
        <Accordion
          title="Will the episodes be publicly available?"
          text="Series trailer and Episode 1 will be available for the public in general. Starting from episode 2, episodes will ONLY be available for YFU NFT holders."
          align='left'
        />
        <Accordion
          title="Where can I find the episodes?"
          text={<>Episodes will be available on the YFU Cast page. Click <a href='https://labs.anime.limited/episodes' target='_blank' rel='noopener noreferrer'>https://labs.anime.limited/episodes</a> to start enjoying the #YFU Verse.</>}
          align='right'
        />
        <Accordion
          title="How many roles can I win per YFU?"
          text="You can only win one role per YFU, so make sure to have several favorites, bid upon them and win all available roles!"
          align='left'
        />
      </div>

    </section>
  );
};

export default FaqV2;
