import React from 'react';

import './about-us.scss';

const AboutUs = () => {
  return (
    <section className='about-us'>
      <div className='top'>
        <div className='left'>
          <img src={require('../../../assets/v2/about/Pantalla2_text.png')} alt='' />
        </div>
        <div className='right'>
          <img src={require('../../../assets/v2/about/Pantalla2_YFUillust.png')} alt='' />
        </div>
      </div>
      <div className='center'>
        <img src={require('../../../assets/v2/about/Pantalla3_Texto.png')} alt='' />
      </div>
      <div className='down'>
        <img src={require('../../../assets/v2/about/Pantalla4_Texto.png')} alt='' />
      </div>

    </section>
  );
};

export default AboutUs;
