import React from 'react';
import Exchange from '../../../exchange/exchange';

import './yfu-section.scss';

const YfuTokenSection = ({ account }) => {
  console.log('account', account);

  return (
    <section className='yfu-section' id='yfuToken'>
      <div className='section-container'>
        <div className='yfu'>
          <div className='yfu-rec-container'>
            <div className='yfu-rec'>
              <img
                src={require('../../../../assets/yfu/welcome-rectangle.png')}
                alt=''
              />
              <p>$YFU TOKEN</p>
            </div>
          </div>

          <div className='yfu-left'>
            <p>
              To purchase YFU Token just connect your wallet and head to the
              exchange pannel!{' '}
            </p>
            <p>
              YFU (ワイフ — /ˈwaɪfuː/) is our a community token for the Anime
              Limited and it’s collectibles ecosystem. It is meant as the core
              governance mechanic for the future generation of
              waifu/anime/defi/crypto enthusiasts. We want to create and
              inclusive and fun community to bring new technologies to a broader
              market and serve as a platform for people to communicate, join and
              learn!
            </p>
            <p>
              YFU (ワイフ — /ˈwaɪfuː/) is our a community token for the Anime
              Limited and it’s collectibles ecosystem. It is meant as the core
              governance mechanic for the future generation of
              waifu/anime/defi/crypto enthusiasts. We want to create and
              inclusive and fun community to bring new technologies to a broader
              market and serve as a platform for people to communicate, join and
              learn!
            </p>
            {/* <img
              className='arrow'
              src={require('../../../../assets/yfu/ex-arrow.png')}
              alt=''
            /> */}
          </div>

          <div className='yfu-right'>
            {account ? (
              <Exchange />
            ) : (
              <>
                <div className='connect-wallet-holder'>
                  <p>CONNECT YOUR WALLET TO START</p>
                </div>
                <img
                  src={require('../../../../assets/yfu/exchance.png')}
                  alt=''
                  className='exchange-img'
                />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default YfuTokenSection;
