import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import getCollection from '../utils/rarible.getCollection';
import { useLocation } from 'react-router-dom';
import Loader from '../utils/Loader';
import { BsChevronLeft } from 'react-icons/bs';

const Nft = (props) => {
  const [collectibles, setCollectibles] = useState([]);
  const [loading, setLoading] = useState(true);
  const { tokenAddress } = props.match.params;
  const { pathname } = useLocation();
  const getData = async () => {
    setLoading(true);
    const { items } = await getCollection();
    // '0x4507584d9c4d399c4f04c3018b6bc3ef89692599'
    // console.log(tokenAddress);

    // let uniqueImages = [
    //   ...new Set(data.map(({ properties }) => properties.image)),
    // ];

    // console.log(uniqueImages);

    //  items = tokenAddress
    //   ? data.filter(({ item }) => item.token == tokenAddress)
    //   : uniqueImages.map((image) =>
    //       data.find((i) => i.properties.image == image)
    //     );

    // const groupByArray = (xs, key) => {
    //   return xs.reduce(function (rv, x) {
    //     let v = x[key];
    //     let el = rv.find((r) => r && r.key === v);
    //     if (el) {
    //       el.values.push(x);
    //     } else {
    //       rv.push({ key: v, values: [x] });
    //     }
    //     return rv;
    //   }, []);
    // };

    setLoading(false);
    setCollectibles(items);
  };

  const nftLayout = (nft, index) => (
    <div className='col-lg-3 col-md-4 col-sm-6 mt-3' key={index}>
      <div className='card'>
        <img
          alt=''
          src={nft?.meta?.image?.url?.BIG}
          className='card-img-top img-fluid round-17'
        />
        <div className='card-body'>
          <div className='text-center'>
            <div>{nft?.meta?.name}</div>
            {!tokenAddress && (
              <div className='gray'>
                {nft.ownership?.value} of {nft.supply}
              </div>
            )}
            <div className='gray'>
              {/* {nft.ownership?.priceEth
                ? `${nft.ownership?.priceEth} Eth`
                : `not for sale`} */}
            </div>
          </div>
          <p>
            {/* {nft?.properties?.description
              ? `${nft?.properties?.description?.substr(0, 15)} ...`
              : ''} */}
          </p>
          {
            <button
              type='buton'
              onClick={() => {
                nft.supply > 1 && !tokenAddress
                  ? props.history.push(`/nft/${nft?.item?.token}`)
                  : props.history.push(`/nft/${nft?.contract}/${nft?.tokenId}`);
              }}
              className='btn btn-primary main-btn btn-block'
            >
              {nft.supply > 1 && !tokenAddress ? 'View All' : 'View item'}
            </button>
          }
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    // const cancelToken = axios.CancelToken;
    // const source = cancelToken.source();
    getData();

    // getCollectibles(source);
    // return () => {
    //   source.cancel('');
    // };
  }, [pathname]);

  return (
    <div className=''>
      {loading ? (
        <div className='text-center'>
          <Loader />
        </div>
      ) : (
        <div className='pt-5 mt-sm-0 p-sm-5 px-3'>
          {tokenAddress ? (
            <Link to={'/nft'}>
              <BsChevronLeft />
              Back
            </Link>
          ) : null}
          <h4 className='orange'>ANIME LIMITED</h4>
          <h1>NFTs</h1>
          <div className='row'>{collectibles.map(nftLayout)}</div>
        </div>
      )}
    </div>
  );
};

export default withNamespaces()(withRouter(Nft));
