import React from 'react';

import './team-members.scss';
import TeamMemberCard from './TeamMemberCard';

const TeamMembers = () => {
  return (
    <section className='tm-section'>
      <div className='section-container'>
        <div className='tm'>
          <div className='tm-rec-container'>
            <div className='tm-rec'>
              <img
                src={require('../../../../assets/yfu/welcome-rectangle.png')}
                alt=''
              />
              <p>TEAM MEMBERS</p>
            </div>
          </div>

          <div className='tm-grid'>
            <TeamMemberCard
              name='KARASU'
              desc='Sharp foresight to innovate with the best technologies.'
              img={require('../../../../assets/bios-team-images/Karasu.png')}
            />
            <TeamMemberCard
              name='ASHTAR'
              desc='Wizard of illusion, blending different mediums of art into one.'
              img={require('../../../../assets/bios-team-images/Ashtar.png')}
            />
            <TeamMemberCard
              name='TOKATL'
              desc='Master of computers and electronics.'
              img={require('../../../../assets/bios-team-images/Tokatl.png')}
            />
            <TeamMemberCard
              name='NIGIRI'
              desc='  A jack of all trades but a master of none.'
              img={require('../../../../assets/bios-team-images/Nigiri.png')}
            />
            <TeamMemberCard
              name='SHINRIN'
              desc='Kokoro Admin. Master in connecting with people. Shinrin-yoku lover.'
              img={require('../../../../assets/bios-team-images/Shinrin.png')}
            />
            <TeamMemberCard
              name='GURO'
              desc={'Master of public relations & community.'}
              img={require('../../../../assets/bios-team-images/Guro.png')}
            />
            <TeamMemberCard
              name='RYUGUJI SAN'
              desc='Written content genious with amazing creative power. '
              img={require('../../../../assets/bios-team-images/RyuGujiSan.png')}
            />
            <TeamMemberCard
              name='KENSHIN F'
              desc='Social Media, lead designer & content creation.'
              img={require('../../../../assets/bios-team-images/KenshinF.png')}
            />
            <TeamMemberCard
              name='KATO'
              desc='Marketing genious with vast technical knowledge.'
              img={require('../../../../assets/bios-team-images/Kato.png')}
            />
            <TeamMemberCard
              name='DRAKAN'
              desc='Animist. Puppet Master. Adept in the Arcane Arts.'
              img={require('../../../../assets/bios-team-images/DRAKAN.png')}
            />
            <TeamMemberCard
              name='ERZA'
              desc='Curious, Resilient & Empathetic Anime enthusiast. Experienced analyst.'
              img={require('../../../../assets/bios-team-images/ERZA.png')}
            />
            <TeamMemberCard
              name='FRAD'
              desc='Community expert. Supreme creative feeling. Keen sight.'
              img={require('../../../../assets/bios-team-images/FRAD.png')}
            />
          </div>

          <div className='section-footer'>
            <p>
              We’re always looking for awesome people who can bring new
              perspectives and life experiences to our projects.
            </p>
            <a
              href='https://forms.gle/mMH6shgzdRhBMiyy6'
              target='_blank'
              rel='noopener noreferrer'
            >
              <button className='btn'>Join The Team</button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamMembers;
