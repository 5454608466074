import React, { useState } from 'react';

import './accordion.scss';
const Accordion = (props) => {
  const [open, setOpen] = useState(false);
  let toggleHandler = () => {
    setOpen(!open);
  };
  return (
    <div className={`accordion ${props.align} ${open ? 'open' : ''}`}>
      <div className="header" onClick={toggleHandler}>
        <h4>{props.title}</h4>
        <img className={open ? 'rotate' : ''} src={require('../../../assets/v2/Flecharrow.png')} alt="" />
      </div>
      <p className="content"> {props.text}</p>
    </div>
  );
};

export default Accordion;
